import { Button, Col, Row } from "antd";
import moment from "moment";
import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import BackgroundImage from "../../../../assets/images/kyc-page-1.png";
import KYCService from "../../../../services/KYCService/kyc.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import OnBoardingContainer from "../../../../store/container/OnBoardingContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { OnBoardingReducerProps } from "../../../../store/reducers/onBoardingReducer";
import { Spin } from 'antd'
import "./customerDetails.scss";
import { Form, Formik, FormikProps } from "formik";
import { Customer } from "../../../../models/Customer/customer.model";
import DatePicker from "../../../../shared/components/DatePicker";

interface CustomerDetailsProps
	extends OnBoardingReducerProps,
	AuthReducerProps {
	openReviewForm: (view: boolean) => void;
	openWrongDetails: (view: boolean) => void;
	alreadyChanged: boolean
	detailsChanged: boolean
}

const CustomerDetails: FC<CustomerDetailsProps> = (props, ref) => {
	const { setCurrentImage, setCurrentText, setKycStep, openReviewForm, alreadyChanged, detailsChanged, openWrongDetails } =
		props;

	const { customer, loading, getCustomerDetails, updateCustomerDetails, btnLoading } = KYCService();

	const formikRef = useRef<FormikProps<Customer>>(null)

	useEffect(() => {
		if (!detailsChanged) {
			formikRef.current?.resetForm()
			getCustomerDetails()
		};
	}, [detailsChanged]);

	useEffect(() => {
		setCurrentImage(BackgroundImage);
		setCurrentText("Made in Kenya");
	}, []);

	useEffect(() => {
		if (customer && customer?.attemptsRemaining === 0) {
			handleNextPage()
		}
	}, [customer])


	const handleNextPage = async (values?: Customer) => {
		if (customer?.isFromIprs && (!customer.dateOfIssue || !customer.dateOfBirth)) {
			if (values?.dateOfBirth && values.dateOfIssue) {
				const response = values && await updateCustomerDetails(values)
				response && setKycStep(1)
			}
		} else
			setKycStep(1)
	};

	return (
		<Spin spinning={loading}>
			<div className="customer-details">
				<h1 className="text-primary text-center">Review your details</h1>
				<Formik
					initialValues={Object.assign(new Customer(), customer)}
					onSubmit={handleNextPage}
					innerRef={formikRef}
					enableReinitialize
				>
					{({ values, setFieldValue }) => {
						return <Form>
							<Row className="details__wrapper" gutter={16}>
								<Col span={24}>
									<h1>
										{customer?.firstName?.toLowerCase()} {customer?.lastName?.toLowerCase()} {customer?.middleName?.toLowerCase()}{" "}
									</h1>{" "}
								</Col>
								<Col span={12}>
									<label htmlFor="">ID Number</label>
									<p >{customer?.kenyanNationalNumber}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="gender">Gender</label>
									<p >{customer?.gender}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="dateOfBirth">DOB</label>
									{customer?.isFromIprs && !customer?.dateOfBirth
										? <DatePicker
											disableFuture
											name={"dateOfBirth"}
											value={values.dateOfBirth}
											placeholder={"Enter date of birth"}
											setFieldValue={(name, val) => setFieldValue(name, val)} />
										: <p className="mt-2">{customer?.dateOfBirth && moment(customer?.dateOfBirth).format("DD MMM YYYY")}</p>
									}

								</Col>
								<Col span={12}>
									<label htmlFor="">District of Birth</label>
									<p className="mt-2">{customer?.districtOfBirth}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="placeOfIssue">Place of issue</label>
									<p className="mt-2">{customer?.placeOfIssue || "-"}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="dateOfIssue">Date of Issue</label>
									{customer?.isFromIprs && !customer?.dateOfIssue
										? <DatePicker
											disableFuture
											name={"dateOfIssue"}
											value={values.dateOfIssue}
											placeholder={"Enter date of issue"}
											setFieldValue={(name, val) => setFieldValue(name, val)} />
										: <p className="mt-2">{customer?.dateOfIssue && moment(customer?.dateOfIssue).format("DD MMM YYYY")}</p>
									}
								</Col>
								<Col span={12}>
									<label htmlFor="">District</label>
									<p>{customer?.district}</p>
								</Col>{" "}
								<Col span={12}>
									<label htmlFor="">Division</label>
									<p>{customer?.division}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Location</label>
									<p>{customer?.location}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Sub-location</label>
									<p>{customer?.subLocation}</p>
								</Col>
							</Row>
							<div className="submit-btn">
								<Button
									type="primary"
									htmlType="submit"
									loading={btnLoading}
								>
									Confirm Details
								</Button>
							</div>
						</Form>
					}}
				</Formik>
				{!alreadyChanged && <p className="text-primary link">
					Wrong ID?{" "}
					<span
						className="text-link cursor-pointer"
						onClick={() => {
							openReviewForm(true);
						}}
					>
						Change here
					</span>
				</p>}
				<p className="text-primary link">
					Incorrect Details?{" "}
					<span
						className="text-link cursor-pointer"
						onClick={() => {
							openWrongDetails(true);
						}}
					>
						Click here
					</span>
				</p>

			</div>
		</Spin>
	);
}

export default AuthContainer(OnBoardingContainer(CustomerDetails));
