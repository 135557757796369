import React, { FC, useEffect } from "react";
import OnBoarding from "../../shared/components/Layout/OnBoarding";
import "./kYCVerification.scss";
import * as appRoutes from "../../routes/routeConstants/appRoutes";

import Timeline from "../../shared/components/Timeline";
import OnBoardingContainer from "../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../store/reducers/onBoardingReducer";
import ReviewDetails from "./ReviewDetails";
import UploadDocuments from "./UploadDocuments";
import VideoKYC from "./VideoKYC";
import RiskAssesment from "./RiskAssesment";
import BankDetailsForm from "./BankDetailsForm";
import KYCService from "../../services/KYCService/kyc.service";
import AuthService from "../../services/AuthService/auth.service";
import { useHistory } from "react-router-dom";

interface KYCVerificationProps extends OnBoardingReducerProps { }

const KYCVerification: FC<KYCVerificationProps> = (props) => {
	const { setKycStep, kycStep } = props;

	const history = useHistory()
	const { getStepperStatus, loading } = KYCService()

	const fetchStep = async () => {
		try {
			const stepData = await getStepperStatus()
			if (!stepData) { return }
			if (stepData?.isSubscribed && stepData?.isKycCompleted && stepData?.isKycVerified) {
				history.push(appRoutes.HOME)
			} else if (stepData?.isKycCompleted) {
				history.push(appRoutes.SUBSCRIPTIONS)
			} else if (stepData.step !== undefined && +stepData?.step >= 0) {
				return getExactTab(typeof stepData?.step === 'string' ? parseInt(stepData?.step) : stepData.step)
				// return getExactTab(2)
			}
			setKycStep(0);
		} catch (error) {
			setKycStep(0);
		}
	}

	const getExactTab = (stepNumber: number) => {
		if (stepNumber === 0) {
			history.push(appRoutes.TELL_US_MORE_ABOUT_YOU)
		} else if (stepNumber > 0 && stepNumber < 5) {
			setKycStep(stepNumber - 1)
		}
	}

	useEffect(() => {
		fetchStep()
	}, []);

	const pages = [
		<ReviewDetails />,
		<UploadDocuments />,
		<VideoKYC />,
		<RiskAssesment />,
		<BankDetailsForm />,
	];

	return (
		<div className="k-y-c-verification">
			{!loading &&
				<OnBoarding><div className="kyc__wrapper">
					<Timeline />
					{pages[kycStep]}
				</div>
				</OnBoarding>}
		</div>
	);
};

export default OnBoardingContainer(KYCVerification);
