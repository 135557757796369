import {
  DoubleLeftOutlined,
  DownOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  Radio,
  RadioChangeEvent,
  Row,
  Skeleton,
  Tabs,
  Tooltip,
} from "antd";
import React, { ChangeEvent, Fragment } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import WalletBalance from "../../../shared/components/WalletBalance";
import PathWhite from "./../../../assets/icons/path-white.svg";
import IncreaseIcon from "./../../../assets/icons/multi color icons - svg/increase.svg";
import InvestmentIcon from "./../../../assets/icons/multi color icons - svg/total investment.svg";
import ExpandIcon from "./../../../assets/icons/multi color icons - svg/expand arrow.svg";
import CollapseIcon from "./../../../assets/icons/multi color icons - svg/collapse arrow.svg";
import MPesaIcon from "./../../../assets/icons/single color icons - svg/Security check.svg";
import "./home.scss";
import { useState } from "react";
import StyledModal from "../../../shared/components/StyledModal";
import SuccessModal from "../../../shared/components/SuccessModal";
import { useEffect } from "react";
import { numberConvention } from "../../../shared/utils/numberConvention";
import ShareService from "../../../services/SharesService/share.service";
import {
  Share,
  ShareBreakdown,
  ShareUnit,
  SingleShareCalculation,
} from "../../../models/Share/share.model";
import AuthService from "../../../services/AuthService/auth.service";
import CheckboxField from "../../../shared/components/CheckboxField";
import { Formik, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { OptionProps } from "../../../shared/types/option.type";
import Logo from "../../../assets/icons/logo - svg/mpesa.png";
import { ShareFormValidationSchema } from "./validation";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import LocalStorage from "../../../shared/LocalStorage";
import termsAndConditionsContainer from "../../../store/container/termsAndconditionsContainer";
import PaymentProcessingModal from "../../../shared/components/PaymentProcessingModal";
import { recursiveRequest } from "../../../shared/utils/recursiveRequest";
import RestrictAccess from "../../../shared/components/RestrictedAccess";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthState } from "../../../store/reducers/authReducer";
import Loader from "../../../shared/components/Loader";
// import { TermsAndContionsModal } from "../../../shared/components/TermsAndContionsModal";
import Notification from "./../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import AlertModal from "../../../shared/components/AlertModal";
import Bank from "../../../assets/icons/multi color icons - svg/bank details.svg";
import BankTransferInstructions from "../../../shared/components/BankTransferInstructions";
import MpesaTransferInstructions from "../../../shared/components/MpesaTransferInstructions";
import DynamicText from "../../../shared/components/DynamicText";
import DynamicFont from 'react-dynamic-font';
import { TermsAndConditionsReducerProps } from "../../../store/reducers/termsAndConditionsReducer";

interface Props extends AuthState, TermsAndConditionsReducerProps {
  accept: boolean;
}

const Home = (props: Props) => {
  const history = useHistory();
  const { TabPane } = Tabs;
  const { showTermsAndConditions, accept, user } = props;

  const [buyMode, setBuyMode] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [subscribeAlertVisible, setSubscribeAlertVisible] =
    useState<boolean>(false);
  const [listVisible, setListVisible] = useState<boolean>();

  const [shareRestrictionVisible, setShareRestrictionVisible] = useState<boolean>(false);

  const [shareModal, setShareModal] = useState<{
    visibility: boolean;
    data: string;
  }>({ visibility: false, data: "" });
  const [shareSuccessModal, setShareSuccessModal] = useState<{
    visibility: boolean;
    data: string;
  }>({ visibility: false, data: "" });
  const [selectedShare, setSelectedShare] = useState<ShareUnit>();
  const [selectedShareUnit, setSelectedShareUnit] = useState<number>();
  const [calcData, setCalcData] = useState<SingleShareCalculation>();
  const [paymentMode, setPaymentMode] = useState<number>(3);
  const [buyShareOption, setBuyShareOption] = useState<number>(1);
  const sharesList = [10, 25, 50, 100, 250, 500, 1000, 2500, 5000];
  const {
    getBuySharesBreakdown,
    getSellSharesBreakdown,
    share,
    loading,
    isSubmitting,
    valuation,
    createBuyShares,
    getSingleShareCalculationData,
    createSellShares,
    getCurrentValuation,
    setShare
  } = ShareService();

  const { getWalletDetails, getUserDetails, wallet, walletLoading } =
    AuthService();

  useEffect(() => {
    // getUserDetails()
    getWalletDetails();
    handleModeChange(buyMode);
    fetchCalculationData();
    getCurrentValuation();
  }, []);
  useEffect(() => { 
    const debounceTimer = setTimeout(() => {
      handleSelectShare(Number(selectedShareUnit),true)
    }, 1500);
    
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [selectedShareUnit]);


useEffect(() => {
  if(selectedShare?.unit){
    setSelectedShareUnit(selectedShare?.unit)
  }
}, [selectedShare?.unit])


  useEffect(() => {
    // if (user && !user.subscription) {
    //     history.push(AppRoutes.SUBSCRIPTIONS, {
    //         redirectFromHome: true
    //     })
    // }
  }, [user]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedShareUnit(Number(event.target.value));
  };
  const fetchCalculationData = async () => {
    try {
      const calculationData = await getSingleShareCalculationData();
      if (calculationData) {
        setCalcData(calculationData);
      }
    } catch (error) { }
  };

  const handleModeChange = (isBuyMode: boolean) => {
    setBuyMode(isBuyMode);
    setSelectedShare(new ShareUnit());
  };




  

  const handleSelectShare = (share: number,isInput=false) => {
    if (loading || (!buyMode && (share || 0) > (wallet?.sellableHoldings || 0))) {
      return;
    }
    if (buyMode && (selectedShare?.unit || 0) + (share || 0) >= 100000) {
      return Notification({
        message: "",
        description: "Max limit is 1,00,000 units",
        type: NotificationTypes.INFO,
      });
    }
    setSelectedShare((prev) => {
      const unit = isInput?share || 0:(prev?.unit || 0) + (share || 0);
      const totalAmount = unit * (calcData?.currentPrice || 0);
      let amount = 0;
      if (buyMode) {
        amount = +(
          totalAmount +
          (totalAmount * (calcData?.buyPercent || 0)) / 100
        ).toFixed(2);
      } else {
        amount = 
        amount = +(
          totalAmount -
          (totalAmount * (calcData?.sellPercent || 0)) / 100
        ).toFixed(2);
      }

      if (unit) {
        if (buyMode) {
          if (buyShareOption == 1) {
            
            getBuySharesBreakdown(buyShareOption, undefined, unit);
          } else {
            getBuySharesBreakdown(buyShareOption, unit, undefined);
          }
        } else {
          getSellSharesBreakdown(unit.toString());
        }
      }

      return {
        unit,
        amount,
      };
    });
    // setTotalShares(prev => prev + (share || 0))
  };
  

  // const handleSelectShareForInput = (share: number) => {
  //   if (loading || (!buyMode && (share || 0) > (wallet?.sellableHoldings || 0))) {
  //     return;
  //   }
  //   if (buyMode && (selectedShare?.unit || 0) + (share || 0) >= 100000) {
  //     return Notification({
  //       message: "",
  //       description: "Max limit is 1,00,000 units",
  //       type: NotificationTypes.INFO,
  //     });
  //   }

  //   setSelectedShare((prev) => {
  //     const unit = share || 0;
  //     const totalAmount = unit * (calcData?.currentPrice || 0);
  //     let amount = 0;
  //     if (buyMode) {
  //       amount = +(
  //         totalAmount +
  //         (totalAmount * (calcData?.buyPercent || 0)) / 100
  //       ).toFixed(2);
  //     } else {
  //       amount = 
  //       amount = +(
  //         totalAmount -
  //         (totalAmount * (calcData?.sellPercent || 0)) / 100
  //       ).toFixed(2);
  //     }
  //     if (unit) {
  //       if (buyMode) {
  //         getBuySharesBreakdown(unit.toString());
  //       } else {
  //         getSellSharesBreakdown(unit.toString());
  //       }
  //     }
  //     return {
  //       unit,
  //       amount,
  //     };
  //   });
  //   // setTotalShares(prev => prev + (share || 0))
  // };

  const handleOpenShareModal = () => {
    // setShareRestrictionVisible(true)
    if (!user?.subscription) {
      setSubscribeAlertVisible(true);
    } else if (selectedShare?.unit) {
      // if (buyMode) {
      //   getBuySharesBreakdown(selectedShare.unit.toString());
      // } else {
      //   getSellSharesBreakdown(selectedShare.unit.toString());
      // }
      setShareModal({
        visibility: true,
        data: buyMode ? "buy" : "sell",
      });
    }
  };

  const subscriptionModalConfirmHandler = () => {
    setSubscribeAlertVisible(false);
    history.push(AppRoutes.SUBSCRIPTIONS);
  };

  const handleTrackShareSuccessModal = () => {
    handleCloseShareSuccessModal();
    history.push(AppRoutes.HOLDINGS, {
      buyMode,
    });
  };

  const handleCloseShareSuccessModal = () => {
    setShareSuccessModal((prev) => {
      return {
        ...prev,
        visibility: false,
      };
    });
  };

  const handleShareSubmit = async (
    values: FormikValues,
    resetForm: Function
  ) => {
    // setAcceptedTC(false)
    LocalStorage.removeItem("has-accepted-terms-and-conditions");
    const shareData = {
      ...values,
      unit: share?.unit,
    };
    try {
      const response = buyMode
        ? await createBuyShares(shareData)
        : await createSellShares(shareData);
      let processedResponse;
      if (response?.dynamicUrl) {
        setIsProcessing(true);
        processedResponse = await recursiveRequest(response.dynamicUrl);
      }
      if (
        processedResponse === "Success" ||
        !buyMode ||
        (buyMode && !response?.dynamicUrl)
      ) {
        setShareSuccessModal({ visibility: true, data: shareModal.data });
      }
      setIsProcessing(false);
      getWalletDetails();
      setShareModal((prev) => {
        return {
          ...prev,
          visibility: false,
        };
      });
      resetForm();
    } catch (error) {
    } finally {
    }
  };

  const clearSelectedShare = () => {
    setSelectedShare(new ShareUnit());
    setSelectedShareUnit(new ShareUnit().unit??0);
  };

  const sharesListTemplateGenerator = () => {
    const listTemplate = sharesList?.map((share) => (
      <p
        key={share}
        className={`share-item ${loading && "disabled"}`}
        onClick={() => handleSelectShare(share)}
      >
        {share || 0}
      </p>
    ));
    return listTemplate;
  };

  const shareActionTabsTemplate = (
    <Tabs
      defaultActiveKey="1"
      size="small"
      
      onChange={(key) => {
        handleModeChange(key === "1")
        setSelectedShare(new ShareUnit())
      }}
    >
      <TabPane tab="Buy Units" key="1" />
      <TabPane tab="Sell Units" key="2" />
    </Tabs>
  );

  const paymentModeChanged = (e: RadioChangeEvent) => {
    setPaymentMode(e.target.value);
  };
  const breakdownTemplate = share?.breakdown?.map((breakdown, i) => (
    <List.Item>
      <span>{breakdown[0]}</span>
      <span
        className={
          (share.breakdown?.length === i + 1 ? " text-bold " : " ") +
          (breakdown[1] < "0" ? " danger " : "")
        }
      >
        {breakdown[1]}
      </span>
    </List.Item>
  ));
  const ShareTemplate = (
    <Formik
      enableReinitialize
      initialValues={{
        isAgreed: accept,
        mobileNumber: "",
        dialCode: 254,
      }}
      validationSchema={
        share?.amountNeeded === 0 ? null : ShareFormValidationSchema
      }
      onSubmit={() => { }}
    >
      {({ setFieldValue, values, resetForm, errors }) => {
        return (
          <Fragment>
            <div className="main-content">
              <p className="title">{'You are confirming to the place the order for '}</p>
              {share?.amount && !loading ? (
                <div className="main-content__amount-container">
                  <h1>
                  <DynamicText
                    text={`${(share?.unit)} Units`}
                    width="100%"
                    fontSize={32}
                  />
                </h1>
                  <h1>
                  <DynamicText
                    text={`${numberConvention(share?.amount)} KES`}
                    width="100%"
                    fontSize={32}
                  />
                </h1>
                </div>
              ) : (
                <Skeleton.Input active={true} size="large" />
              )}
            </div>
            <div className="content-template">
              {buyMode && !!share?.amountNeeded && (
                <Fragment>
                  <Row align="middle">
                    <Col span={2}>
                      <Radio
                        value={1}
                        checked={paymentMode === 1}
                        onChange={paymentModeChanged}
                      />
                    </Col>
                    <Col span={22}>
                      <div className="wallet-form__payment-method justify-between">
                        <p>Pay using Bank Account</p>
                        <div className="bank-icon__container">
                          <img
                            className="m-pesa-icon"
                            src={Bank}
                            alt="m-pesa-icon"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {paymentMode === 1 && (
                    <BankTransferInstructions
                      customerId={String(user?.uid).split("-").pop() || ""}
                    />
                  )}
                  <Divider />
                  <Row align="middle">
                    <Col span={2}>
                      <Radio
                        value={2}
                        checked={paymentMode === 2}
                        onChange={paymentModeChanged}
                      />
                    </Col>
                    <Col span={22}>
                      <div className="wallet-form__payment-method justify-between">
                        <p>Pay using Mpesa App</p>
                        <img
                          className="m-pesa-icon"
                          src={Logo}
                          alt="m-pesa-icon"
                        />
                      </div>
                    </Col>
                  </Row>
                  {paymentMode === 2 && (
                    <MpesaTransferInstructions
                      amount={share?.amountNeeded}
                      customerId={String(user?.uid).split("-").pop() || ""}
                    />
                  )}
                  <Divider />
                  <Row align="middle">
                    <Col span={2}>
                      <Radio
                        value={3}
                        checked={paymentMode === 3}
                        onChange={paymentModeChanged}
                      />
                    </Col>
                    <Col span={22}>
                      <div className="wallet-form__payment-method">
                        <p>Pay using</p>
                        <img
                          className="m-pesa-icon"
                          src={Logo}
                          alt="m-pesa-icon"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  {paymentMode === 3 && (
                    <InputField
                      type="text"
                      name="mobileNumber"
                      className="mpesa-field lg"
                      placeholder="Enter MPESA mobile number"
                    />
                  )}
                </Fragment>
              )}
              <List>
                {share?.breakdown && !loading
                  ? breakdownTemplate
                  : new Array(4).fill(" ").map((_) => (
                    <List.Item>
                      <Skeleton.Input active={true} size="small" />
                      <Skeleton.Input
                        active={true}
                        size="small"
                        className="mini-skeleton"
                      />
                    </List.Item>
                  ))}
              </List>
              {paymentMode !== 3 && (
                <div className="styled-modal-btn mt-5">
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => {
                      setPaymentMode(3);
                      setShareModal((prev) => {
                        return {
                          ...prev,
                          visibility: false,
                        };
                      });
                    }}
                  >
                    Okay
                  </Button>
                </div>
              )}
            </div>
            {paymentMode === 3 && (
              <div className="footer">
                <Form>
                  <Checkbox
                    className="terms-acceptance--checkbox"
                    name="isAgreed"
                    checked={accept}
                    onChange={showTermsAndConditions}
                  >
                    <span className="terms-acceptance-label">
                      I have read all the{" "}
                      <b onClick={showTermsAndConditions}>
                        Terms and Conditions
                      </b>{" "}
                      and agree to the same
                    </span>
                  </Checkbox>
                  <div className="styled-modal-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
          
                      disabled={!accept || (buyMode && !!errors.mobileNumber)}
                      onClick={() => handleShareSubmit(values, resetForm)}
                      loading={isSubmitting}
                    >
                      {buyMode
                        ? share?.amountNeeded
                          ? `Add ${numberConvention(share?.amountNeeded) || 0
                          } KES to Wallet & Buy Units`
                          : "Confirm Buy Units"
                        : "Confirm Sell Units"}
                    </Button>
                  </div>
                  {buyMode && (
                    <p className="payment_form__footer">
                      <img src={MPesaIcon} alt="mpesa" />
                      MPESA: 100% Payment Protection
                    </p>
                  )}
                </Form>
              </div>
            )}
          </Fragment>
        );
      }}
    </Formik>
  );

  const buyUnitOptions = [
    {label: "Units", value: 1},
    {label: "Amount", value: 0}
  ]

 

  const handleBuyShareUnits = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedShareUnit(undefined)
    setBuyShareOption(value);
    setShare(new ShareBreakdown())
    
  }

  
  return user?.kycAccepted === undefined ? (
    <Loader />
  ) : user?.kycAccepted ? (
    <Fragment>
      <PageHeader
        titleBadge={user?.subscription?.name}
        title={"Hello, " + (user?.kycBasicInfo?.firstName?.toLowerCase() || "")}
        showWallet={true}
        showSubtitles={true}
        showUpgrade={true}
        investment_details={user.investmentDetails}
      />
      {user?.investmentDetails && (
        <div className="investment-summary__container">
          <div className="investment-summary__content">
            <div className="investment-summary__content-investment">
              <img
                src={InvestmentIcon}
                alt="unit"
                className="investment-summary--investment--icon"
              />
              <h4 className="investment-summary--heading">
                Your total investment for the year{" "}
                {user?.investmentDetails?.investedYear}:
              </h4>
              <h4 className="investment-summary--amount">
                KES{" "}
                {`${numberConvention(
                  user?.investmentDetails?.investmentAmount
                )}`}
              </h4>
            </div>
            <h3 className="investment-summary--divider"></h3>
            <div className="investment-summary__content-count">
              <i className=" icon-holdings investment-summary--icon-holdings" />
              <h4 className="investment-summary--heading">You have invested</h4>
              <h4 className="investment-summary--amount">
                {user?.investmentDetails?.investmentTime}{" "}
                {user?.investmentDetails?.investmentTime === 1
                  ? "time"
                  : "times"}{" "}
                in the past {user?.investmentDetails?.investmentMonths}{" "}
                {user?.investmentDetails?.investmentMonths === 1
                  ? "month"
                  : "months"}
              </h4>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="dashboard__container">
          <div className="dashboard__container--one">
            <div className="dashboard__current--front">
              <div className="current-valuation">
                <p className="current-valuation--title">Current Valuation</p>
                <DynamicText
                  text={`${numberConvention(valuation?.currentValuation)} KES`}
                  className="current-valuation--value"
                  width="100%"
                  fontSize={40}
                />
              </div>
              <div className="statistics">
                <Tooltip title="Total units held">
                  <p>
                    <i className="icon-holdings-selected" />
                    {wallet?.sellableHoldings ?? 0} units
                  </p>
                </Tooltip>
                <Tooltip title="% Returns">
                  <p>
                    <img
                      className="increase-icon"
                      src={IncreaseIcon}
                      alt="unit"
                    />
                    {valuation?.returnsPercent ?? 0} %
                  </p>
                </Tooltip>
              </div>
              <div className="total-investment">
                <p className="total-investment--title">Total Investment</p>
                <p className="total-investment--value">
                  <img src={InvestmentIcon} alt="unit" />
                  <DynamicText
                    text={` ${numberConvention(
                      valuation?.totalInvestment
                    )} KES`}
                    className="display-inline"
                    width="100%"
                    fontSize={24}
                  />
                </p>
              </div>
              <div
                className="swipe-left"
                onClick={() => {
                  setListVisible(true);
                }}
              >
                <img src={ExpandIcon} alt="expand" />
              </div>
            </div>
            <div
              className={
                "dashboard__current--side " +
                (listVisible === true
                  ? "slide-in"
                  : listVisible === false
                  ? "slide-out"
                  : "")
              }
            >
              <List>
                <List.Item>
                  <span className="font-light"> Total investment</span>
                  <span>
                    {numberConvention(valuation?.totalInvestment)} KES
                  </span>
                </List.Item>
                <List.Item>
                  <span className="font-light">Total units held</span>
                  <span> {valuation?.holdings} units </span>
                </List.Item>
                <List.Item>
                  <span className="font-light">Dividend earned</span>
                  <span>{valuation?.dividendsEarned} KES</span>
                </List.Item>
                <List.Item>
                  <span className="font-light">Capital appreciation</span>
                  <span>{valuation?.capitalAppreciation} KES</span>
                </List.Item>
                <List.Item>
                  <span className="font-light">Interest earned</span>
                  <span>{valuation?.interestsEarned} % </span>
                </List.Item>
                <List.Item>
                  <span className="font-light">% Returns</span>
                  <span>{numberConvention(valuation?.returnsPercent)} %</span>
                </List.Item>
                <List.Item>
                  <span className="font-light">Current valuation</span>
                  <span className="bold">
                    {numberConvention(valuation?.currentValuation)} KES
                  </span>
                </List.Item>
              </List>
              <div
                className="swipe-right"
                onClick={() => {
                  setListVisible(false);
                }}
              >
                <img src={CollapseIcon} alt="expand" />
              </div>
            </div>
          </div>
          <div
            className={
              "dashboard__shares " + (buyMode ? "buy-shares" : "sell-shares")
            }
          >
            <div className="shares__header">
              {shareActionTabsTemplate}
              {/* <p>
                                <i className="icon-ic-info-24px" />
                                Terms and Conditions</p> */}
            </div>
            {buyMode && (
              <div className="shares__details--unit-container">
                <Radio.Group
                  options={buyUnitOptions}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={handleBuyShareUnits}
                  defaultValue={buyUnitOptions[0].value}
                />
              </div>
            )}
            <div className="shares__details">
              <div className="shares__details--amount">
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  <Input
                    bordered={true}
                    allowClear={false}
                    type="number"
                    value={selectedShareUnit === 0 ? "" : selectedShareUnit}
                    onChange={handleInputChange}
                    className="shares__details--input"
                    min={0}
                  />
                )}
              </div>
              {/* <ReloadOutlined
                className="reset-icon"
                onClick={clearSelectedShare}
              /> */}
              <div className="shares__details--button share-btn-pc">
                <Button type="primary" onClick={handleOpenShareModal}>
                  {buyMode ? "Buy" : "Sell"} {` ${share?.unit || 0} units for`}
                  {loading ? (
                    <Skeleton.Button active />
                  ) : (
                    <span className="text-bold">
                      {/* {selectedShare?.amount || 0} KES */}
                      {numberConvention(share?.amount || 0)} KES
                    </span>
                  )}
                  <Tooltip title={`incl ${buyMode ? "0" : "1" }% service tax`}>
                    <i className="icon-ic-info-24px" />
                  </Tooltip>
                </Button>
              </div>
            </div>
            <div className="shares__footer">
              {sharesListTemplateGenerator()}
            </div>
            {/* mobile view button */}
            <div className="shares__details--button share-btn-mobile">
              <Button type="primary" onClick={handleOpenShareModal}>
                {buyMode ? "Buy" : "Sell"} units for
                <span className="text-bold">
                  {/* {selectedShare?.amount || 0} KES */}
                  {numberConvention(share?.amount || 0)} KES
                </span>
                <Tooltip title={`incl ${buyMode ? "0" : "1" }% service tax`}>
                  <i className="icon-ic-info-24px" />
                </Tooltip>
              </Button>
            </div>
          </div>
        </div>
        <div className="dashboard__wallet">
          <WalletBalance
            wallet={wallet}
            loading={walletLoading}
            fetchWallet={getWalletDetails}
          ></WalletBalance>
        </div>
        <StyledModal
          visible={shareModal.visibility}
          title={shareModal.data === "buy" ? "Buy Units" : "Sell Units"}
          className={shareModal.data === "buy" ? "yellow-bg" : "blue-bg"}
          dynamicWalletBalance={wallet?.dynamicBalance || 0}
          staticWalletBalance={wallet?.staticBalance || 0}
          content={ShareTemplate}
          okText={
            shareModal.data === "buy"
              ? "Add 8,000 to Wallet & Buy Units"
              : "Confirm Sell Units"
          }
          onCancel={() => {
            setPaymentMode(3);
            setShareModal((prev) => {
              return {
                ...prev,
                visibility: false,
              };
            });
          }}
        />
        <SuccessModal
          shareAction={shareSuccessModal.data}
          visible={shareSuccessModal.visibility}
          onOk={handleTrackShareSuccessModal}
          onCancel={handleCloseShareSuccessModal}
        />
        <AlertModal
          visible={subscribeAlertVisible}
          okText="Choose a plan"
          className="mini-modal"
          maskClosable={true}
          onOk={subscriptionModalConfirmHandler}
          onCancel={() => setSubscribeAlertVisible(false)}
        >
          You don't have an active subscription to place an order
        </AlertModal>
        <AlertModal
          visible={shareRestrictionVisible}
          okText="Close"
          className="mini-modal"
          maskClosable={true}
          onOk={() => setShareRestrictionVisible(false)}
          onCancel={() => setShareRestrictionVisible(false)}
        >
          Due to our first annual REIT performance results being announced
          today, we are not accepting new orders till midnight of 2nd April,
          2022. All your orders already placed will be processed and your
          accounts updated soon
        </AlertModal>
      </div>
      <PaymentProcessingModal visible={isProcessing} />
    </Fragment>
  ) : (
    <RestrictAccess user={user} getUserDetails={getUserDetails} />
  );
};

export default AuthContainer(termsAndConditionsContainer(Home));
