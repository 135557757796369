import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const createAccountValidationSchema = yup.object().shape({
	email: yup
		.string()
		.email("Enter a valid email")
		.required("Email filed is required"),
	mobileNumber: yup
		.string()
		.matches(REGEX.NUMERIC, "Mobile number should be of numeric and should not start with 0")
		.min(9, "Mobile number must be at least 9 characters")
		.max(10, "Mobile number must have maximum 10 characters")
		.required("Mobile number is required!"),
	password: yup
		.string()
		.min(8, "Password has to be longer than 8 characters!")
		.required("Password is required!")
		.matches(
			REGEX.PASSWORD,
			"One Uppercase, One Lowercase, One Number and one special case Character"
		),
	confirmPassword: yup
		.string()
		.required("Confirm password is required!")
		.oneOf([yup.ref("password"), ""], "Passwords must match"),
});
