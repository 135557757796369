import { UploadDocumentModal } from './../../models/Documents/documents.model';
import { KYCVideo } from "./../../models/KYCVideo/kycVideo.model";
import React, { useEffect, useState } from "react";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CustomerType } from "../../models/CustomerType/customerType.model";
import { BankDetail, Customer } from "../../models/Customer/customer.model";
import { RiskAssesment } from "../../models/RiskAssesment/riskAssesment.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { Documents } from "../../models/Documents/documents.model";
import { convertJSONToFormData } from "../../shared/utils/FormDataConvertor";
import { KYCStep } from "../../models/KYCStep/kycStep.model";
import axios from 'axios';
import { ContainerClient, BlobServiceClient } from '@azure/storage-blob';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { CountryMeta } from '../../models/Country/country.model';


const KYCService = () => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [riskAssesments, setRiskAssesments] = useState<RiskAssesment[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [videoCode, setVideoCode] = useState<string>();
  const [bankDetails, setBankDetail] = useState<BankDetail>();
  const [countries, setCountries] =useState<CountryMeta[]>([]);

  // useEffect(() => {
  //   if (customer?.isFromIprs === 0) {
  //     setCustomer(prev => {
  //       return {
  //         ...new Customer(),
  //         isFromIprs: 0
  //       }
  //     })
  //   }
  // }, [customer])

  const updateUserInfo = async (customerType: CustomerType) => {
    setLoading(true);
    try {
      const UserJSON = {
        ...serialize(customerType),
      };
      const response = await axiosInstance.post(
        ApiRoutes.USER_ROLE_INFO,
        UserJSON
      );
      if (response.data.success) {
        const customer = deserialize(Customer, response.data["kyc_basic_info"]);
        setCustomer(customer);
        return customer;
      }
      Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  };


  const updateNationalNumber = async (customerType: CustomerType) => {
    setLoading(true);
    try {
      const UserJSON = {
        ...serialize(customerType),
      };
      const response = await axiosInstance.put(
        ApiRoutes.USER_ROLE_INFO,
        UserJSON
      );
      if (response.data.success) {
        const customer = deserialize(Customer, response.data["kyc_basic_info"]);
        setCustomer(customer);
        return customer;
      }
      Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const getCustomerDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.USER_ROLE_INFO);
      if (response.data.success) {
        const customer = deserialize(Customer, response.data["kyc_basic_info"]);
        if (customer?.isFromIprs) {
          setCustomer(customer);
        } else {
          setCustomer({ ...new Customer(), userRole: customer.userRole, isFromIprs: 0 })
        }
        return;
      }
      Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const updateCustomerDetails = async (customer: Customer) => {
    setBtnLoading(true);
    try {
      const payload = serialize(Customer, customer)
      const response = await axiosInstance.put(ApiRoutes.USER_ROLE_INFO_UPDATE, payload);
      return true
    } catch (error) {

    } finally {
      setBtnLoading(false);
    }
  };

  const getRiskAssesmentQuestions = () => {
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.RISK_ASSESMENT_QUESTIONS)
      .then((response) => {
        const questions = deserialize(RiskAssesment, response.data['questions'] as []);
        setRiskAssesments(questions);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadKYCDocuments = async (documents: UploadDocumentModal, showNotification?: boolean) => {
    setLoading(true);
    const serializedDocument = { ...serialize(UploadDocumentModal, documents) };
    const serializedFormData = convertJSONToFormData(serializedDocument);
    try {
      const response = await axiosInstance.post(
        ApiRoutes.ID_PROOF_UPLOAD,
        serializedFormData
      );
      if (response.data.success) {
        if (showNotification) {
          Notification({
            message: "",
            description: response.data.message,
            type: NotificationTypes.SUCCESS,
          });
        }
        return true;
      }
      return false;
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const getKYCVideoCode = async () => {
    try {
      const response = await axiosInstance.get(ApiRoutes.VIDEO_PROOF_CODE)
      const data = deserialize(KYCVideo, response.data);
      setVideoCode(data?.verificationCode);
    } catch (error) {

    }
  };

  const getStepperStatus = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.KYC_STEP)
      if (response.data.success) {
        const data = deserialize(KYCStep, response.data.data);
        return data;
      }
      return
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  };

  const createBlobInContainer = async (containerClient: ContainerClient, file: File) => {
    const blobClient = containerClient.getBlockBlobClient(file.name);
    const options = { blobHTTPHeaders: { blobContentType: file.type, "x-ms-version": "2017-11-09" } };
    const response = await blobClient.uploadData(file, options);
    console.log(response)
    return response
  }

  const uploadFileToBlob = async (file: File, url: string) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(url);
    const containerClient = blobService.getContainerClient("vukastage");
    const response = await createBlobInContainer(containerClient, file);
    console.log(response)
  };

  const uploadKYCVideo = async (videoData: KYCVideo) => {
    try {

      const API_URL = await generateUploadUrl(videoData)
      setLoading(true);

      // const uppy = new Uppy({ debug: true, autoProceed: true })
      // uppy.use(XHRUpload, {
      //   endpoint: API_URL,
      //   formData: true,
      //   fieldName: '/',
      //   method: "PUT",
      //   headers: {
      //     "x-ms-blob-type": "BlockBlob",
      //   }
      // })
      // videoData.verificationVideo && uppy.addFile({
      //   data: videoData.verificationVideo
      // })
      // const uppyResponse = await uppy.upload()

      // if (uppyResponse.failed.length)
      //   throw uppyResponse.failed

      // Storage blob
      // videoData.verificationVideo && await uploadFileToBlob(videoData.verificationVideo, API_URL)

      setLoading(true);
      const azureAxiosInstance = axios.create({
        timeout: 180000
      });
      azureAxiosInstance.interceptors.request.use(function (config) {
        config.headers = {
          "x-ms-blob-type": "BlockBlob",
          "Access-Control-Allow-Origin": "*"
        };
        return config;
      });

      const { status } = await azureAxiosInstance.put(API_URL, videoData.verificationVideo)

      const fileNames = videoData.verificationVideo?.name.split(".") || []
      const sucessPayload = {
        "extension": fileNames[fileNames?.length - 1],
        "code": videoData.code
      };
      const response = await axiosInstance.post(
        ApiRoutes.VIDEO_PROOF_UPLOAD,
        {
          ...sucessPayload,
          status
        }
      );
      if (response.data.success) {
        Notification({
          message: "",
          description: response.data.message,
          type: NotificationTypes.SUCCESS,
        });
        return true;
      }
      Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
      return false;
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      setLoading(false);
    }
  };

  const generateUploadUrl = async (videoData: KYCVideo) => {
    setLoading(true);
    try {
      const fileNames = videoData.verificationVideo?.name.split(".") || []
      const payload = {
        "extension": fileNames[fileNames?.length - 1],
        "code": videoData.code
      };
      const response = await axiosInstance.put(
        ApiRoutes.VIDEO_UPLOAD_LINK,
        payload
      );
      return response.data["url"];
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  };

  const uploadRiskAssesmentAnswer = async (answers: number[]) => {
    setLoading(true);
    try {
      const answerJSON = {
        answers,
      };
      const response = await axiosInstance.post(
        ApiRoutes.RISK_ASSESMENT,
        answerJSON
      );
      if (response.data.success) {
        return true;
      }
      Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
      return false;
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const createKycBankDetails = async (bankDetails: BankDetail) => {
    setLoading(true);
    try {
      const bankDetailJSON = {
        ...serialize(bankDetails),
      };

      const response = await axiosInstance.post(
        ApiRoutes.KYC_BANK_DETAILS,
        bankDetailJSON
      );
      if (response.data.success) {
        Notification({
          message: "",
          description: response.data.message,
          type: NotificationTypes.SUCCESS,
        });
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);

    } finally {
      setLoading(false);
    }
  };

  const updateKycBankDetails = async (bankDetails: BankDetail) => {
    setLoading(true);
    try {
      const bankDetailJSON = {
        ...serialize(BankDetail, bankDetails),
      };

      const response = await axiosInstance.put(
        ApiRoutes.KYC_BANK_DETAILS,
        bankDetailJSON
      );
      if (response.data.success) {
        const data = deserialize(BankDetail, response.data["kyc_bank_detail"])
        setBankDetail(data)
        Notification({
          message: "",
          description: response.data.message,
          type: NotificationTypes.SUCCESS,
        });
        return true;
      }
      return false;
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  };

  const getCountries = async() => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(ApiRoutes.COUNTRIES);
      const countries = deserialize(CountryMeta,response.data.countries )as CountryMeta[];
      setCountries(countries);
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  }

  return {
    riskAssesments,
    error,
    loading,
    customer,
    btnLoading,
    updateCustomerDetails,
    bankDetails,
    getRiskAssesmentQuestions,
    updateUserInfo,
    uploadKYCDocuments,
    uploadRiskAssesmentAnswer,
    updateKycBankDetails,
    getCustomerDetails,
    videoCode,
    getKYCVideoCode,
    uploadKYCVideo,
    getStepperStatus,
    createKycBankDetails,
    updateNationalNumber,
    countries,
    getCountries,
    
  };
};

export default KYCService;
